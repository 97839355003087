import styled from '@emotion/styled'
import { Grid } from '@mui/material'
import { Content, SecondaryElement } from '../components'

interface Props {
  pair?: boolean
}

const TITLE = 'CONTACT ME'
const SUBTITLE = `get in touch with me`

const DATA = [
  // {
  //   id: 2,
  //   title: 'Phone',
  //   content: '+506 8706-0797',
  // },
  {
    id: 3,
    title: 'Email',
    content: 'rocaes90@gmail.com',
  },
]

export const Contact = ({ pair = false }: Props) => {
  return (
    <Content pair={pair} title={TITLE} subtitle={SUBTITLE}>
      <SectionContent container>
        {DATA.map((info, index) => (
          <SecondaryElement key={index} info={info} size={6} />
        ))}
      </SectionContent>
    </Content>
  )
}

const SectionContent = styled(Grid)`
  padding: 60px 40px;
`
