import styled from '@emotion/styled'
import { Grid, Typography } from '@mui/material'
import { Content, SecondaryElement } from '../components'

interface Props {
  pair?: boolean
}

const DATA = [
  {
    id: 1,
    title: 'Location',
    content: 'Alajuela, Costa Rica',
  },
  // {
  //   id: 2,
  //   title: 'Phone',
  //   content: '+506 8706-0797',
  // },
  {
    id: 3,
    title: 'Email',
    content: 'rocaes90@gmail.com',
  },
]

export const Presentation = ({ pair = false }: Props) => {
  return (
    <Content pair={pair}>
      <SectionContent container>
        <PrincipalContent item xs={12}>
          <FirstName>roiner</FirstName>
          <LastName>CAMACHO</LastName>
          <Position variant="body1">Software Engineer & Front-end Expert</Position>
        </PrincipalContent>
        <SecondaryContent container>
          {DATA.map((info, index) => (
            <SecondaryElement key={index} info={info} />
          ))}
        </SecondaryContent>
      </SectionContent>
    </Content>
  )
}

const SectionContent = styled(Grid)`
  height: 100vh;
  padding: 0 40px;
`

const PrincipalContent = styled(Grid)`
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

const SecondaryContent = styled(Grid)`
  height: 20vh;
  border-top: ${({ theme }) => `1px solid ${theme.palette.divider}`};
`

const FirstName = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 3.75rem;
  font-weight: 300;
  line-height: 1;
`

const LastName = styled(Typography)`
  color: #e0a80d;
  font-size: 3.75rem;
  font-weight: 500;
  line-height: 1;
`

const Position = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-top: 35px;
`
